<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <b-row>
                  <b-col cols="6">
                    <p>Chọn file để thêm sinh viên:</p>
                    <b-form-file
                      ref="inputFileRef"
                      accept=".xls, .xlsx"
                      @change="importExcel"
                    />
                  </b-col>
                  <b-col cols="6">
                    <div style="margin-top: 35px; float: right;">
                      <b-button
                        v-show="creatable && mainClassData.length > 0"
                        variant="success"
                        @click="importStudent()"
                      >
                        <span class="text-nowrap text-right">
                          <feather-icon icon="CheckSquareIcon" /> Import và tạo tài khoản
                        </span>
                      </b-button>
                      <b-button
                        variant="secondary"
                        style="margin-left: 10px"
                        @click="downloadFile"
                      >
                        <span class="text-nowrap text-right">
                          <feather-icon icon="DownloadIcon" /> Tải file mẫu
                        </span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <hr>
                <b-row v-show="this.excelList.length > 0">
                  <b-col cols="6">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="Hệ đào tạo:"
                      label-for="trainingSystemSelected"
                    >
                      <v-select
                        v-model="trainingSystemSelected"
                        :options="trainingSystems"
                        :reduce="option => option.value"
                        :clearable= false
                        @input="onChangeTrainingSystem"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="Khóa học:"
                      label-for="courseSelected"
                    >
                      <v-select
                        v-model="courseSelected"
                        :options="courses"
                        :reduce="option => option.value"
                        @input="onChangeCourse"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="Ngành học:"
                      label-for="majorSelected"
                    >
                      <v-select
                        v-model="majorSelected"
                        :options="majors"
                        :reduce="option => option.value"
                        @input="onChangeMajor"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="Chương trình đào tạo:"
                      label-for="programmeSelect"
                    >
                      <v-select
                        v-model="programmeSelect"
                        :options="programmes"
                        :reduce="option => option.value"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="Chia theo số lượng lớp:"
                      label-for="planNum"
                    >
                      <b-form-input
                        id="planNum"
                        v-model="planNum"
                        name="planNum"
                        type="number"
                        placeholder="Để trống nếu muốn tạo tự động"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <div class="text-right">
                      <b-button
                        v-show="this.programmeSelect > 0"
                        variant="primary"
                        @click="onCreatePlanClass"
                      >
                        <span class="text-nowrap text-right">Xem lớp dự kiến</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  v-show="this.excelList.length > 0"
                  class="mt-0"
                >
                  <b-col cols="12">
                    <vue-good-table
                      class="my-table"
                      row-style-class="vgt-row"
                      style-class="vgt-table striped bordered"
                      :columns="columnsTable"
                      :rows="mainClassData"
                      :pagination-options="paginationOptions"
                      max-height="500px"
                    >
                      <div
                        slot="emptystate"
                        style="text-align: center; font-weight: bold"
                      >
                        Không có bản ghi nào !
                      </div>
                      <template
                        slot="table-row"
                        slot-scope="props"
                      >
                        <span v-if="props.column.field === 'status'">
                          {{ getStatusName(props.row.status) }}
                        </span>
                        <!-- Column: Common -->
                        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                      </template>
                    </vue-good-table>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Per Page -->
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-1">
                  <span class="text-nowrap"> Hiển thị {{serverParams.perPage * (serverParams.page-1) +1}}
                    đến {{(serverParams.perPage * (serverParams.page) >= total)? total : serverParams.perPage * (serverParams.page)}} &nbsp; của {{ totalRecords }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="table.fields"
                  :rows="table.items"
                  :total-rows="totalRecords"
                  :pagination-options="{
                    enabled: true,
                    mode: 'pages',
                  }"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{serverParams.perPage * (serverParams.page-1) +1}}
                        đến {{(serverParams.perPage * (serverParams.page) >= total)? total : serverParams.perPage * (serverParams.page)}} &nbsp; của {{ total }} bản ghi
                      </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="serverParams.perPage"
                          :options="['10', '20', '50']"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRecords"
                          :per-page="serverParams.perPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>

          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import {
  BButton,
  BFormFile,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Workbook } from '@syncfusion/ej2-excel-export'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Student',
  components: {
    BFormInput,
    vSelect,
    BFormGroup,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      paginationOptions: {
        enabled: false,
      },
      excelList: [],
      programmeSelect: undefined,
      trainingSystemSelected: undefined,
      courseSelected: undefined,
      majorSelected: undefined,
      planNum: 1,
      itemsBC: [
        {
          text: 'Admin',
          href: '#',
        },
        {
          text: 'Student Management',
          href: '#',
        },
        {
          text: 'Import Student',
          active: true,
        },
      ],
      table: {
        fields: [
          {
            field: 'stt', label: 'STT', sortable: false, type: 'string',
          },
          {
            field: 'code', label: 'Mã sinh viên', sortable: false, type: 'string',
          },
          {
            field: 'lastName', label: 'Họ đêm', sortable: false, type: 'string',
          },
          {
            field: 'name', label: 'Tên', sortable: false, type: 'string',
          },
          {
            field: 'identifyCode', label: 'CCCD', sortable: false, type: 'string',
          },
          {
            field: 'passportCode', label: 'Hộ khẩu', sortable: false, type: 'string',
          },
          {
            field: 'birthday', label: 'Ngày sinh', sortable: false, type: 'date', dateInputFormat: 'dd/mm/yyyy', dateOutputFormat: 'dd/mm/yyyy',
          },
          {
            field: 'gender', label: 'Giới tính', sortable: false, type: 'string',
          },
          {
            field: 'birthPlace', label: 'Nơi sinh', sortable: false, type: 'string',
          },
          {
            field: 'permanentResidence', label: 'Hộ khẩu', sortable: false, type: 'string',
          },
          {
            field: 'districtCode', label: 'Mã huyện', sortable: false, type: 'string',
          },
          {
            field: 'provinceCode', label: 'Mã tỉnh', sortable: false, type: 'string',
          },
          {
            field: 'address', label: 'Địa chỉ LH', sortable: false, type: 'string',
          },
          {
            field: 'religionCode', label: 'Mã tôn giáo', sortable: false, type: 'string',
          },
          {
            field: 'ethnicCode', label: 'Mã dân tộc', sortable: false, type: 'string',
          },
          {
            field: 'policyId', label: 'Mã CĐCS', sortable: false, type: 'string',
          },
          {
            field: 'profileId', label: 'Mã hồ sơ', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      tenFile: '',
      dataExcel: [],
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 10,
      },
      user: getUser(),
    }
  },
  computed: {
    ...mapGetters({
      total: 'rooms/total',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      majors: 'dropdown/majors',
      programmes: 'dropdown/programmes',
      mainClassData: 'diploma/planLists',
    }),
    columnsTable() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
        },
        {
          label: 'Tên lớp',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Mã lớp',
          field: 'code',
          sortable: false,
        },
        {
          label: 'Khóa',
          field: 'courseName',
          sortable: false,
        },
        {
          label: 'Chuyên ngành',
          field: 'majorName',
          sortable: false,
        },
      ]
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.IMPORT_STUDENT_PROGRAMME)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getTrainingSystems({ organizationId: this.user.orgId })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setTrainingSystems: 'dropdown/SET_TRAINING_SYSTEMS',
      setCourses: 'dropdown/SET_COURSES',
      setMajors: 'dropdown/SET_MAJORS',
      setProgrammes: 'dropdown/SET_PROGRAMMES',
    }),
    ...mapActions({
      importClassStudents: 'importStudent/importClassStudents',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getMajors: 'dropdown/getMajors',
      getProgrammes: 'dropdown/getProgrammes',
      getClassPlanData: 'diploma/getPlanData',
    }),
    onChangeTrainingSystem() {
      if (this.trainingSystemSelected == null) {
        this.setCourses([])
        this.setMajors([])
        this.setProgrammes([])
        this.courseSelected = null
        this.majorSelected = null
        this.programmeSelect = null
      } else {
        this.getCourses({
          organizationId: this.user.orgId,
          trainingSystemId: this.trainingSystemSelected,
        })
      }
    },
    onChangeCourse() {
      if (this.courseSelected == null) {
        this.setMajors([])
        this.setProgrammes([])
        this.majorSelected = null
        this.programmeSelect = null
      } else {
        this.getMajors({
          organizationId: this.user.orgId,
          trainingSystemId: this.trainingSystemSelected,
          courseId: this.courseSelected,
        })
      }
    },
    onChangeMajor() {
      if (this.majorSelected == null) {
        this.setProgrammes([])
        this.programmeSelect = null
      } else {
        this.getProgrammes({
          organizationId: this.user.orgId,
          trainingSystemId: this.trainingSystemSelected,
          courseId: this.courseSelected,
          majorId: this.majorSelected,
        })
      }
    },
    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      this.readFile(files)
    },
    readFile(files) {
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsName = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsName], {
            header: 0,
            defval: '',
          })
          // Edit data
          if (ws.length > 0) {
            ws.forEach(item => {
              this.excelList.push(item)
            })
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', '')
            return
          }
          if (
            // eslint-disable-next-line no-prototype-builtins
            !(this.excelList[0].hasOwnProperty('MA_SINH_VIEN')
              && this.excelList[0].hasOwnProperty('HO_DEM')
              && this.excelList[0].hasOwnProperty('TEN')
              && this.excelList[0].hasOwnProperty('CCCD')
              && this.excelList[0].hasOwnProperty('HO_CHIEU')
              && this.excelList[0].hasOwnProperty('NGAY_SINH')
              && this.excelList[0].hasOwnProperty('GIOI_TINH')
              && this.excelList[0].hasOwnProperty('NOI_SINH')
              && this.excelList[0].hasOwnProperty('HO_KHAU')
              && this.excelList[0].hasOwnProperty('MA_HUYEN')
              && this.excelList[0].hasOwnProperty('MA_TINH')
              && this.excelList[0].hasOwnProperty('DIA_CHI_LH')
              && this.excelList[0].hasOwnProperty('MA_TON_GIAO')
              && this.excelList[0].hasOwnProperty('MA_DAN_TOC')
              && this.excelList[0].hasOwnProperty('MA_CDCS')
              && this.excelList[0].hasOwnProperty('MA_HS')
            )
          ) {
            this.showToast('File chưa đúng định dạng', 'XCircleIcon', 'danger', '')
            return
          }

          this.dataExcel = []
          // eslint-disable-next-line no-plusplus
          for (let k = 0; k < this.excelList.length; k++) {
            const item = this.excelList[k]
            const obj = {
              stt: k + 1,
              name: item.TEN,
              lastName: item.HO_DEM,
              code: item.MA_SINH_VIEN,
              identifyCode: item.CCCD,
              passportCode: item.HO_CHIEU,
              orderNo: '',
              birthday: item.NGAY_SINH,
              relatedId: null,
              organizationId: this.user.orgId,
              studentId: null,
              birthPlace: item.NOI_SINH,
              gender: item.GIOI_TINH,
              address: item.DIA_CHI_LH,
              permanentResidence: item.HO_KHAU,
              districtCode: item.MA_HUYEN,
              provinceCode: item.MA_TINH,
              religionCode: item.MA_TON_GIAO,
              ethnicCode: item.MA_DAN_TOC,
              policyCode: item.MA_CDCS,
              profileId: item.MA_HS,
              dateEnter: '',
              status: 1,
              delFlag: 0,
              createdBy: null,
            }
            if ((this.dataExcel.filter(e => e.code === obj.code).length > 0)) {
              this.showToast('Trùng mã sinh viên!', 'XCircleIcon', 'danger', obj.code)
              return
            }
            this.dataExcel.push(obj)
          }
          this.table.items = this.dataExcel
          this.totalRecords = this.dataExcel.length
          if (this.totalRecords > 10) {
            this.table.items = this.dataExcel.slice(0, 10)
          }

          // insert ban ghi
        } catch (e) {
          alert(`Read failure!${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
    },

    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    async onCreatePlanClass() {
      await this.getClassPlanData(
        {
          programmeId: this.programmeSelect,
          planStudentNum: this.excelList.length,
          planClassNum: this.planNum,
        },
      )
    },
    async importStudent() {
      this.isLoading = true
      try {
        const res = await this.importClassStudents({
          planClasses: this.mainClassData,
          planStudents: this.dataExcel,
        })
        if (res.code === 1) {
          this.showToast('Lưu thành công', 'CheckIcon', 'success', '')
          this.resetInputFile()
        } else {
          this.showToast('Có lỗi xảy ra', 'XCircleIcon', 'danger', res.message)
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    downloadFile() {
      const columns = [
        { index: 1, width: 130 },
        { index: 2, width: 130 },
        { index: 3, width: 100 },
        { index: 4, width: 130 },
        { index: 5, width: 130 },
        { index: 6, width: 200 },
        { index: 7, width: 200 },
        { index: 8, width: 130 },
        { index: 9, width: 130 },
        { index: 10, width: 130 },
        { index: 11, width: 130 },
        { index: 12, width: 130 },
        { index: 13, width: 130 },
        { index: 14, width: 130 },
        { index: 15, width: 130 },
        { index: 16, width: 130 },

      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'MA_SINH_VIEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'HO_DEM',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'TEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'CCCD',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 5,
            value: 'HO_CHIEU',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 6,
            value: 'NGAY_SINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 7,
            value: 'GIOI_TINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 8,
            value: 'NOI_SINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 9,
            value: 'HO_KHAU',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 10,
            value: 'MA_HUYEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 11,
            value: 'MA_TINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 12,
            value: 'DIA_CHI_LH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 13,
            value: 'MA_TON_GIAO',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 14,
            value: 'MA_DAN_TOC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 15,
            value: 'MA_CDCS',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 16,
            value: 'MA_HS',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }

      rows.push(headerRow)
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('import_SinhVienCTDT.xlsx')
    },
    // handle even

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    onPageChange(params) {
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

    onPerPageChange(params) {
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

  },
}
</script>

<style scoped>

</style>
